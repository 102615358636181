<template>
  <div class="manageOrganizeList-wrapper">
    <!-- @update:searchParams="setSearchParams" -->
    <list
      ref="list"
      exportPermission="export"
      :exportUrl="exportURL"
      :exportMethod="'get'"
      :searchUrl="searchUrl"
      :searchParams="searchParams"
      :extraParams="extraParams"
      :headers="headers"
      :isMultiSelect="true"
    >
      <template #headerSlot>
      </template>
      <template #tableLeftSlot>
        <div class="table-left" v-show="treeShow">
          <!-- 搜索框 -->
          <div class="tree-search">
            <v-input v-model="filterText" prefix-icon="el-icon-search" placeholder="请输入名称" />
          </div>
          <!-- 类型选择 -->
          <div class="tree-select" ref="treeSelect">
            <v-button v-model="treeType" class="select-button"
              :class="{select: showTreeTypeList}"
              @click.stop="showTreeTypeList = !showTreeTypeList"
            >
              {{treeTypeMap[treeType]}}<i v-show="treeTypeFilterOps.length > 0" class="el-icon-d-caret"></i>
            </v-button>
            <v-button text="新增" class="add-button" permission="addTreeNode" @click="createOrg" />
            <ul class="tree-select__list" v-show="showTreeTypeList && treeTypeFilterOps.length > 0">
              <li v-for="(item, index) in treeTypeFilterOps" :key="index" @click.stop="treeTypeClick(item)">{{item.text}}</li>
            </ul>
          </div>
          <!-- 控件树 -->
          <div class="tree-group">
            <button-tree
              ref="buttonTree"
              v-loading="treeLoading"
              :key="keyTree"
              :data="treeData"
              :defaultProps="treeProps"
              :btnOps="btnOps"
              :filterText="filterText"
              :filterNodeMethod="filterNodeMethod"
              :defaultExpandedKeys="defaultExpandedKeys"
              :currentNodeKey="currentNodeKey"
              :highlightCurrent="true"
              :expand-on-click-node="false"
              @buttonClick="buttonNode"
              @nodeClick="nodeClick"
            />
          </div>
        </div>
        <div class="treeSwith" @click="treeSwith">
          <img v-if="treeShow" :src="treeOffImgSrc" />
          <img v-if="!treeShow" :src="treeOnImgSrc" />
        </div>
      </template>
      <template #searchSlot>
        <v-input label="姓名" v-model="searchParams.userName" placeholder="请输入姓名" />
        <v-input label="手机号" v-model="searchParams.mobileNum" placeholder="请输入手机号" />
        <v-input label="工号" v-model="searchParams.jobNum" placeholder="请输入工号" />
        <v-select label="性别" placeholder="请选择性别" v-model="searchParams.sex" :options="sexOps" />
        <v-select
          label="账号类型"
          v-model="searchParams.roleType"
          :options="roleTypeFilterOps"
        />
        <template v-if="searchParams.roleType != '107' && searchParams.roleType">
          <v-select2
            label="管理范围"
            v-model="searchParams.organizeId"
            :subjoin="roleExtraParams"
            v-bind="organizeParams"
          />
          <v-select2
            label="角色"
            v-model="searchParams.roleId"
            :subjoin="rolesExtraParams"
            v-bind="roleParams"
          />
        </template>
        <v-select label="账号状态" v-model="searchParams.accountStatus" :options="statusOps" />
      </template>
      <template #operateSlot="scope">
        <v-button permission="edit" text="基本信息" type="text" @click="edit(scope.row)" />
        <el-dropdown trigger="click" v-if="scope.row.accountStatus != 2 ">
          <span class="el-dropdown-link">
            更多<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown" v-if="scope.row.accountStatus != 2">
            <el-dropdown-item>
              <v-button text="职责" v-if="treeType == 2" permission="responsibility" type="text" @click="responsibility(scope.row)" />
            </el-dropdown-item>
            <el-dropdown-item>
              <v-button text="关闭"  permission="delete" type="text" @click="close(scope.row)" />
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <template #batchSlot="scope">
        <v-button text="批量关闭" permission="batchDelete" @click="batchRemove(scope.selectedData)"></v-button>
        <v-button text="批量移动" permission="batchMove" @click="batchMove(scope.selectedData)"></v-button>
      </template>
    </list>
    <el-dialog class="dialog-tree__node" :title="dialogText" width="540px" :visible.sync="dialogVisible">
      <el-form ref="form" label-width="100px" :model="form" :key="key">
        <!-- 添加或编辑组织节点、或者添加公司 -->
        <template v-if="dialogValue === 1 || dialogValue === 3">
          <el-form-item
            label="组织名称"
            prop="orgName"
            :rules="[ { required: true, message: '请输入组织名称', trigger: 'blur' } ]"
          >
            <v-input placeholder="请输入组织名称" v-model="form.orgName" :width="width"/>
          </el-form-item>
        </template>
        <!-- 查询组织节点下成员 -->
        <template v-if="dialogValue === 2">
          <p class="p-mar-0">确定删除组织节点：<span style="color:red;">{{currentData && currentData[treeProps.label]}}</span></p>
        </template>
        <!-- 移动组织节点 -->
        <template v-if="dialogValue === 4">
          <p class="p-text p-pad-l">组织节点&nbsp;<span style="color:red;">{{currentData && currentData[treeProps.label]}}</span></p>
          <el-form-item
            label="移动至"
            prop="parentId"
            :rules="[ { required: true, message: '请选择组织节点', trigger: 'blur' } ]"
          >
            <select-tree
              :value.sync="form.parentId"
              placeholder="查询组织节点"
              :treeData="treeData"
              :treeProps="treeProps"
              :highlightCurrent="true"
              :filterNodeMethod="filterNodeMethod"
              :expand-on-click-node="false"
              :width="width"
            />
          </el-form-item>
        </template>
        <!-- 批量移动人员 -->
        <template v-if="dialogValue === 6">
          <p class="p-text p-pad-l">所有人员共{{ids.length}}人</p>
          <el-form-item
            label="移动至"
            prop="parentId"
            :rules="[ { required: true, message: '请选择组织节点', trigger: 'blur' } ]"
          >
            <select-tree
              :value.sync="form.parentId"
              :data.sync="treeOrgData"
              placeholder="查询组织节点"
              :treeData="treeData"
              :treeProps="treeProps"
              :highlightCurrent="true"
              :filterNodeMethod="filterNodeMethod"
              :expand-on-click-node="false"
              :width="width"
            />
          </el-form-item>
        </template>
        <!-- 删除公司 -->
        <template  v-if="dialogValue === 5">
          <p class="p-mar-0">确定删除公司&nbsp;<span style="color:red;">{{currentData[treeProps.label]}}</span>？</p>
          <p class="p-mar-0">删除公司后，该公司下所有成员账号立即失效！</p>
        </template>
        <!-- 删除组织节点 -->
        <template v-if="dialogValue === 7">
          <p class="p-mar-0">组织节点&nbsp;<span style="color:red;">{{currentData[treeProps.label]}}</span>&nbsp;下存在成员，如需删除该组织节点，请移除组织下成员</p>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <v-button @click="dialogVisible = false" style="margin-right:10px" text="取 消" v-if="dialogValue !== 7"></v-button>
        <v-button type="primary" v-loading="loading" @click="clickCheck" text="确 定"></v-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRoleTypeURL,
  getOrganizeURL,
  getEnterOrgTreeListURL,
  addOrgNodeURL,
  updateOrgNodeURL,
  updateUserOrgNodesURL,
  updateOrgInformationURL,
  selectUserInfoNumByOrgIdURL,
  deleteURL,
  deleteUserInfoNumByOrgIdURL,
  selectEnterpriseUserInfoListURL,
  getRolesURL,
  exportURL,
  batchURL
} from './api'
import {
  regionTypeOps,
  sexOps,
  sexMap,
  statusOps,
  statusMap,
  btnOps,
  treeTypeOps,
  treeTypeMap
} from './map'
import { ButtonTree, SelectTree } from 'components/bussiness/index'
import treeOnImgSrc from './img/treeOn.png'
import treeOffImgSrc from './img/treeOff.png'

export default {
  name: 'manageOrganizeList',
  components: {
    'button-tree': ButtonTree,
    'select-tree': SelectTree
  },
  data () {
    return {
      width: 320,
      exportURL,
      searchUrl: selectEnterpriseUserInfoListURL,
      regionTypeOps,
      sexOps: sexOps(1),
      sexMap,
      statusOps: statusOps(1),
      statusMap,
      roleTypeOps: [],
      organizeParams: {
        searchUrl: getOrganizeURL,
        request: {
          text: 'orgName_',
          value: 'orgId_'
        }
      },
      roleParams: {
        searchUrl: getRolesURL,
        request: {
          text: 'roleName_',
          value: 'roleId_'
        }
      },
      searchParams: {
        userName: '',
        mobileNum: '',
        jobNum: '',
        sex: undefined,
        roleType: undefined,
        organizeId: '',
        roleId: undefined,
        orgId: ''
      },
      extraParams: {
      },
      headers: [
        {
          prop: 'userName',
          label: '姓名'
        },
        {
          prop: 'sex',
          label: '性别',
          formatter (row) {
            return sexMap[row.sex]
          }
        },
        {
          prop: 'mobileNum',
          label: '手机号'
        },
        {
          prop: 'parentOrgName',
          label: '所属组织'
        },
        {
          prop: 'jobNum',
          label: '工号'
        },
        {
          prop: 'adminDuty',
          label: '职务'
        },
        {
          prop: 'jobRank',
          label: '职级'
        },
        {
          prop: 'orgName',
          label: '管理范围'
        },
        {
          prop: 'roleName',
          label: '角色'
        },
        {
          prop: 'accountStatus',
          label: '账号状态',
          formatter (row) {
            return statusMap[row.accountStatus]
          }
        }
      ],
      // tree
      type: 0, // typeTree 改变的方式 1 表示通过按钮改变
      type2: 0, // currentNodeKey 改变的方式 1 表示组件更新时改变
      keyTree: 0,
      treeLoading: false,
      currentData: null, // 按钮点击时保存的节点数据
      currentItem: null, // 按钮点击时保存的按钮数据
      treeSelect: null, // 元素节点 el
      event: { // 存储事件的回调方法,用于组件销毁时去掉定时器
        click: null
      },
      showTreeTypeList: false,
      treeOnImgSrc,
      treeOffImgSrc,
      treeShow: true,
      filterText: '',
      btnOps,
      treeData: [],
      treeProps: {
        id: 'id', // 必须
        parentId: 'parentId',
        children: 'childOrgList', // 必须
        label: 'orgName', // 必须
        // isLeaf: (data, node) => {    // 必须
        //   return data.hasChild === 0
        // },
        btn: 'operateArr' // 必须
      },
      treeOrgData: {}, // select-button值对应的数据
      currentNodeKey: '',
      defaultExpandedKeys: [],
      treeType: undefined,
      treeTypeOps: treeTypeOps(),
      treeTypeFilterOps: [],
      treeTypeMap,
      // 弹框
      ids: [], // 记录批量移动人员的ids
      loading: false,
      key: 0,
      form: {
        orgId: '',
        orgName: '',
        parentId: ''
      },
      dialogValue: '',
      dialogText: '',
      dialogVisible: false
    }
  },
  computed: {
    userInfo () {
      return this.$store.state.userInfo
    },
    menuPermission () {
      return this.$store.state.menuStore.menuPermission
    },
    // treeTypeFilterOps () {
    //   return this.treeTypeOps.filter(d => {
    //     return this.$store.getters.getPermission(d.permission)
    //   })
    // },
    roleExtraParams () { // 管理范围
      return {
        roleType: this.searchParams.roleType
      }
    },
    rolesExtraParams () { // 角色
      let organizeId = ''
      if (this.userInfo.userType === '101') {
        organizeId = this.userInfo.orgId
      }
      return {
        roleType: this.searchParams.roleType,
        organizeId: organizeId || this.searchParams.organizeId
      }
    },
    roleTypeFilterOps () {
      // 2/3 物业管理公司/社区,账号只能选集团以下的角色, 1 平台职能部门 只能选 集团+系统
      let ops = this.roleTypeOps
      if (Number(this.treeType) === 1) {
        ops = this.roleTypeOps.filter(v => v.value === '100' || v.value === '106')
      } else if (Number(this.treeType) === 2 || Number(this.treeType) === 3) {
        ops = this.roleTypeOps.filter(v => v.value !== '100' && v.value !== '106')
      }
      let opsAll = [{ text: '全部', value: undefined }, ...ops]
      return opsAll
    }
  },
  watch: {
    dialogVisible (val) {
      this.key += 1
      if (!val) {
        // 弹窗关闭时，需要调一次列表接口
        this.form = {
          orgId: '',
          parentId: '',
          orgName: ''
        }
      }
    },
    treeType (val) {
      console.log(val, 'leixng1')
      // 通过点击按钮导致的treeType改变,才触发下列代码
      this.currentData = null
      this.currentItem = null
      this.filterText = ''
      let orgId = this.searchParams.orgId
      this.searchParams = {
        orgId,
        userName: '',
        mobileNum: '',
        jobNum: '',
        sex: undefined,
        roleType: undefined,
        organizeId: '',
        roleId: ''
      }
      this.treeData = []
      if (this.type === 1) {
        // if (this.currentNodeKey === '') {
        //   console.log('此处触发2')
        //   this.$refs.list.searchData()
        // }
        this.currentNodeKey = ''
        this.defaultExpandedKeys = []
        this.type = 0
        this.getTreeList()
      }
    },
    currentNodeKey (val, old) {
      // 1. 获取当前选中的key
      this.$set(this.searchParams, 'orgId', val)
      // console.log('currentNodeKey', `${val}----${old}`)
      // 2. 弹框状态和该组件更新时导致 currentNodeKey 的改变不触发查询列表
      if (!this.dialogVisible && this.type2 !== 1) {
        // console.log('此处触发1', this.searchParams)
        this.$refs.list.searchData(this.searchParams)
      }
      this.type2 = 0
    }
  },
  created () {
    this.getRoleType()
    /** 用于平台、物业、社区三个选项的权限问题 */
    const permission = this.menuPermission.manageOrganizeList
    this.treeTypeFilterOps = this.treeTypeOps.filter(v => Number(permission[v.permission]) === 1)
    if (this.treeTypeFilterOps.length) {
      this.treeType = this.treeTypeFilterOps[0].value
    }
    /** end */
  },
  mounted () {
    /** 定义事件, 组件销毁时清空 */
    this.treeSelect = this.$refs.treeSelect
    this.event.click = () => {
      // console.log('点击body')
      this.showTreeTypeList = false
    }
    document.body.addEventListener('click', this.event.click)
    /** end */
    const orgTree = sessionStorage.getItem('orgTree')
    if (orgTree) {
      this.type2 = 1 // 当组件更新导致的 currentNodeKey 变化,不触发列表的查询
      const { orgId, orgParentId, orgType } = JSON.parse(orgTree)
      this.defaultExpandedKeys = orgParentId ? [String(orgParentId)] : []
      this.currentNodeKey = String(orgId)
      this.searchParams.orgId = String(orgId) // 默认调取一次列表接口时，参数确保正确
      this.treeType = orgType
      sessionStorage.removeItem('orgTree')
    }
    this.getTreeList()
    this.$setBreadcrumb('管理组织')
  },
  destroyed () {
    document.body.removeEventListener('click', this.event.click)
  },
  methods: {
    createOrg () { // 新增
      if (this.treeType === 2) {
        // 跳转到新增租户页面
        this.$router.push({
          name: 'tenantForm',
          orgId: '',
          orgParentId: '',
          orgType: this.treeType
        })
      } else {
        this.dialogText = '添加组织'
        this.dialogValue = 1
        this.dialogVisible = true
        this.currentData = null
        this.currentItem = null
      }
    },
    async batchRemove ({ data }, status = 0) { // 批量关闭
      const ids = data.map(v => v.userId)
      if (ids.length === 0) {
        this.$message('请选择成员')
        return
      }
      let isOk = await this.$confirm(`确认关闭所选成员共${ids.length}人？`)
      let _ids = ids.join(',')
      let dataArr = []
      data.forEach(item => {
        dataArr.push(`${item.userName}-${item.loginName}`)
      })
      isOk && this.updateStatus(_ids, status, dataArr)
    },
    batchMove ({ ids }) { // 批量移动
      this.ids = ids
      if (ids.length === 0) {
        this.$message('请选择成员')
        return
      }
      this.dialogVisible = true
      this.dialogValue = 6
    },
    edit (row) { // 编辑按钮
      this.$router.push({
        name: 'memberInfoForm',
        query: {
          id: row.userId,
          newId: row.id,
          orgId: row.orgId,
          orgParentId: row.parentOrgId,
          orgType: this.treeType
        }
      })
    },
    async close (row, status = 0) { // 关闭按钮
      let msg = '确认关闭该成员账号？'
      let isOk = await this.$confirm(msg)
      isOk && this.updateStatus(row.userId, status, [`${row.userName}-${row.loginName}`])
    },
    // 职责
    responsibility (row) {
      this.$router.push({
        name: 'responsibilityForm',
        query: {
          orgId: row.orgId,
          userId: row.userId,
          userName: row.userName,
          mobileNum: row.mobileNum
        }
      })
    },

    updateStatus (ids, status, dataArr) { // 关闭账号接口
      // 操作日志添加操作对象 规则：角色名称-归属层级
      let dataObject = dataArr.join(';')

      let _this = this
      let postData = {
        userIds: ids.split(','),
        status, // 0 关闭 1 开启
        dataObject: dataObject
      }
      _this.$axios({
        method: 'put',
        url: batchURL,
        data: postData
      }).then(res => {
        if (res.status === 100) {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },

    /** 列表查询条件 */
    getRoleType () { // 账号类型 100/101/102...
      this.$axios.get(getRoleTypeURL).then((res) => {
        if (res.status === '100') {
          const { data } = res
          this.roleTypeOps = data.map((item) => {
            return {
              text: item.name,
              value: item.id
            }
          })
        }
      })
    },

    /** 树节点相关 */
    getTreeList () { // 获取树节点数据
      this.treeLoading = true
      this.$axios.get(`${getEnterOrgTreeListURL}?orgType=${this.treeType}`)
        .then((res) => {
          if (res.status === 100 && res.msg === 'ok') {
            this.treeData = res.data
            this.keyTree += 1
          }
        }).finally(() => {
          this.treeLoading = false
        })
    },
    treeSwith () { // 树的显示隐藏
      this.treeShow = !this.treeShow
    },
    treeTypeClick (item) { // 选择树节点类型 - 平台职能部门、物业管理公司、社区治理单位
      this.showTreeTypeList = !this.showTreeTypeList
      // type 1 - 表示通过点击按钮跳转类型 0 表示别的方式导致treeType的修改
      this.type = 1
      this.treeType = item.value
      console.log(1)
    },
    filterNodeMethod (value, data) { // 过滤树节点的方法
      // value 为 this.filterText 的值, data 为当前节点的数据对象-接口获取
      if (!value) return true
      return data[this.treeProps.label].indexOf(value) !== -1
    },
    buttonNode (data, item) { // 点击树节点的弹框按钮
      // data 为节点对象的数据, item 为按钮的对象数据
      this.currentData = data
      this.currentItem = item
      if (item.title) {
        this.dialogText = item.title
        this.dialogValue = item.code
        this.dialogVisible = true
        /**  物业管理公司第一级为租户, 需特殊处理 */
        const onOff = this.treeType === 2 &&
          item.value === 'deteleBtn' &&
          data[this.treeProps.btn].length === 4
        if (onOff) {
          this.dialogText = '删除公司'
          this.dialogValue = 5
        }
      } else {
        // 添加成员 + 设置按钮
        this.toJumpRoute(data, item)
      }
      // 以下为按钮的特殊需求
      if (item.value === 'editBtn') { // 当点击编辑按钮时
        this.form.orgName = this.currentData[this.treeProps.label]
      }
    },
    toJumpRoute (data, item) { // 跳转路由
      if (item.value === 'addMemberBtn') { // 添加人员
        this.$router.push({
          name: 'memberInfoForm',
          query: {
            orgId: data[this.treeProps.id],
            orgParentId: data[this.treeProps.parentId],
            orgType: this.treeType
          }
        })
        // console.log(this.treeType, 'this.treeType')
      } else if (item.value === 'setBtn') { // 设置
        this.$router.push({
          name: 'tenantForm',
          query: {
            tenantId: data['tenantId'],
            orgId: data[this.treeProps.id],
            orgParentId: data[this.treeProps.parentId],
            orgType: this.treeType
          }
        })
      }
    },
    nodeClick (data, node, nodeVue) { // 点击树节点文字
      /* data 节点数据对象, node 节点的vue虚拟对象, nodeVue 节点组件本身 */
      const val = data && (data[this.treeProps.id] || undefined)
      this.defaultExpandedKeys = val ? [val] : []
      this.currentNodeKey = val
    },

    /** 弹框相关 */
    async clickCheck () {
      let onOff = true
      this.$refs.form.validate(result => {
        onOff = result
      })
      if (!onOff) {
        return
      }
      this.loading = true
      let result = false
      // 点击确认,总共6种情况 1添加组织节点 2查询组织节点下成员 3编辑组织节点 4移动组织节点 5删除公司 6批量移动 7删除组织节点
      if (this.dialogValue === 1) {
        result = await this.addOrgNode()
      } else if (this.dialogValue === 2) {
        result = await this.searchDeteOrgNode()
      } else if (this.dialogValue === 3) {
        result = await this.editOrgNode()
      } else if (this.dialogValue === 4) {
        result = await this.moveOrgNode()
      } else if (this.dialogValue === 5) {
        result = await this.deteCompyNode()
      } else if (this.dialogValue === 6) {
        result = await this.batchMoveUser()
      } else if (this.dialogValue === 7) {
        result = true
      }
      this.loading = false
      if (result) {
        this.dialogVisible = false
        this.$refs.list.searchData()
      }
    },
    async addOrgNode () {
      let isSuccess = false
      await this.$axios.post(addOrgNodeURL, {
        orgName: this.form.orgName,
        orgType: this.treeType,
        parentId: this.currentData ? this.currentData[this.treeProps.id] : ''
      }).then((res) => {
        if (res.status === 100 && res.msg === 'ok') {
          isSuccess = true
          const val = this.currentData && (this.currentData[this.treeProps.id] || undefined)
          this.defaultExpandedKeys = val ? [val] : []
          this.currentNodeKey = val
          this.getTreeList()
        }
      })
      return isSuccess
    },
    async searchDeteOrgNode () { // 查询删除组织节点是否存在人员
      let isSuccess = false
      // 先查是否存在人员
      await this.$axios.get(`${selectUserInfoNumByOrgIdURL}?id=${this.currentData[this.treeProps.id]}`)
        .then(async (res) => {
          if (res.status === 100 && res.msg === 'ok') {
            if (res.data === 0) {
              isSuccess = await this.deteOrgNode()
            } else {
              this.dialogValue = 7
            }
          }
        })
      return isSuccess
    },
    async deteOrgNode () {
      let isSuccess = false
      await this.$axios.post(deleteUserInfoNumByOrgIdURL, { id: this.currentData[this.treeProps.id] })
        .then(res => {
          if (res.status === 100 && res.msg === 'ok') {
            const val = this.currentData && (this.currentData[this.treeProps.parentId] || undefined)
            this.defaultExpandedKeys = val ? [val] : []
            this.currentNodeKey = val
            this.getTreeList()
            isSuccess = true
          }
        })
      return isSuccess
    },
    async editOrgNode () {
      let isSuccess = false
      await this.$axios.post(updateOrgInformationURL, {
        id: this.currentData[this.treeProps.id],
        orgName: this.form.orgName
      }).then(res => {
        if (res.status === 100 && res.msg === 'ok') {
          const val = this.currentData && (this.currentData[this.treeProps.id] || undefined)
          const parentVal = this.currentData && (this.currentData[this.treeProps.parentId] || undefined)
          this.defaultExpandedKeys = parentVal ? [parentVal] : []
          this.currentNodeKey = val
          this.getTreeList()
          isSuccess = true
        }
      })
      return isSuccess
    },
    async moveOrgNode () {
      let isSuccess = false
      await this.$axios.post(updateOrgNodeURL, {
        id: this.currentData[this.treeProps.id],
        parentId: this.form.parentId
      }).then(res => {
        if (res.status === 100 && res.msg === 'ok') {
          const val = this.currentData && (this.currentData[this.treeProps.id] || undefined)
          // this.defaultExpandedKeys = val ? [val] : []
          this.defaultExpandedKeys = this.form.parentId ? [this.form.parentId] : []
          this.currentNodeKey = val
          this.getTreeList()
          isSuccess = true
        }
      })
      return isSuccess
    },
    async batchMoveUser () {
      let isSuccess = false
      await this.$axios.post(updateUserOrgNodesURL, {
        ids: this.ids,
        orgId: this.form.parentId
      }).then(res => {
        if (res.status === 100 && res.msg === 'ok') {
          this.currentData = this.treeOrgData
          const val = this.currentData && (this.currentData[this.treeProps.id] || undefined)
          const parentVal = this.currentData && (this.currentData[this.treeProps.parentId] || undefined)
          this.defaultExpandedKeys = parentVal ? [parentVal] : []
          this.currentNodeKey = val
          this.getTreeList()
          isSuccess = true
        }
      })
      return isSuccess
    },
    async deteCompyNode () { // 删除公司节点调原有接口 - TenantList 页面列表删除按钮
      let isSuccess = false
      const url = `${deleteURL}${this.currentData['tenantId']}`
      await this.$axios.delete(url).then(res => {
        if (res.status === 100 && res.msg === 'ok') {
          const val = this.currentData && (this.currentData[this.treeProps.parentId] || undefined)
          this.defaultExpandedKeys = val ? [val] : []
          this.currentNodeKey = val
          this.getTreeList()
          isSuccess = true
        }
      })
      return isSuccess
    }
  }
}
</script>
<style lang="scss" scoped>
.manageOrganizeList-wrapper {
  .table-left {
    height: 100%;
    display: inline-block;
    position: relative;
    .tree-search {
      text-align: center;
      ::v-deep .v-control {
        width: 100%;
        .el-input {
          width: 100% !important;
          .el-input__inner {
            outline: none;
            padding: 2px 10px 2px 30px;
            height: 33px;
          }
          .el-input__icon {
            line-height: 33px;
          }
          .el-input__suffix {
            line-height: 33px;
          }
        }
      }
    }
    ::v-deep .el-loading-mask {
      z-index: 100!important;
    }
    .tree-select {
      width: 100%;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      position: absolute;
      top: 38px;
      left: 0;
      display: flex;
      z-index: 200;
      .select-button {
        width: 100%;
        // height: 100%;
        flex: 1;
        ::v-deep .el-button {
          padding: 0 8px;
          text-align: left;
          color: #414142;
          background-color: #e3e5e9;
          border-color: #e8e9eb;
          i {
            float: right;
          }
        }
      }
      .select {
        ::v-deep .el-button {
          background: linear-gradient(90deg, #43a8ff 0%, #529fff 99%);
          color: #fff;
        }
      }
      .add-button {
        margin-left: 8px;
        ::v-deep .el-button{
          padding: 0 8px;
        }
      }
      .tree-select__list {
        position: absolute;
        top: 30px;
        left: 0;
        text-align: left;
        display: inline-block;
        width: 100%;
        border: 1px solid #e4e7ed;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
        z-index: 200;
        > li {
          cursor: pointer;
          padding: 0px 12px;
          color: #909399;
          &:hover {
            color: #1B8CF2;
            background-color: #f5f7fa;
          }
        }
      }
    }
    .tree-group {
      margin-top: 40px;
      height: calc(100% - 73px);
      width: 240px;
    }
    .treeSwith {
      cursor: pointer;
      display: inline-block;
      position: relative;
      height: 100%;
      vertical-align: top;
      width: 36px;
      img {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 36px;
        height: 80px;
      }
      &-bar {
        width: 8px;
        height: 160px;
        background: linear-gradient(
          to top left,
          rgba(228, 228, 228, 1) 0%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    }
  }
  .dialog-tree__node {
    text-align: left;
    .p-text {
      margin-top: 0;
    }
    .p-mar-0 {
      margin: 0;
    }
    .p-pad-l {
      padding-left: 45px;
    }
    .el-form-item:last-child {
      margin-bottom: 0;
    }
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "manageOrganizeList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "export",
          exportUrl: _vm.exportURL,
          exportMethod: "get",
          searchUrl: _vm.searchUrl,
          searchParams: _vm.searchParams,
          extraParams: _vm.extraParams,
          headers: _vm.headers,
          isMultiSelect: true,
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
          {
            key: "tableLeftSlot",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.treeShow,
                        expression: "treeShow",
                      },
                    ],
                    staticClass: "table-left",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tree-search" },
                      [
                        _c("v-input", {
                          attrs: {
                            "prefix-icon": "el-icon-search",
                            placeholder: "请输入名称",
                          },
                          model: {
                            value: _vm.filterText,
                            callback: function ($$v) {
                              _vm.filterText = $$v
                            },
                            expression: "filterText",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { ref: "treeSelect", staticClass: "tree-select" },
                      [
                        _c(
                          "v-button",
                          {
                            staticClass: "select-button",
                            class: { select: _vm.showTreeTypeList },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                _vm.showTreeTypeList = !_vm.showTreeTypeList
                              },
                            },
                            model: {
                              value: _vm.treeType,
                              callback: function ($$v) {
                                _vm.treeType = $$v
                              },
                              expression: "treeType",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.treeTypeMap[_vm.treeType])
                            ),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.treeTypeFilterOps.length > 0,
                                  expression: "treeTypeFilterOps.length > 0",
                                },
                              ],
                              staticClass: "el-icon-d-caret",
                            }),
                          ]
                        ),
                        _c("v-button", {
                          staticClass: "add-button",
                          attrs: { text: "新增", permission: "addTreeNode" },
                          on: { click: _vm.createOrg },
                        }),
                        _c(
                          "ul",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.showTreeTypeList &&
                                  _vm.treeTypeFilterOps.length > 0,
                                expression:
                                  "showTreeTypeList && treeTypeFilterOps.length > 0",
                              },
                            ],
                            staticClass: "tree-select__list",
                          },
                          _vm._l(_vm.treeTypeFilterOps, function (item, index) {
                            return _c(
                              "li",
                              {
                                key: index,
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.treeTypeClick(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.text))]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "tree-group" },
                      [
                        _c("button-tree", {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.treeLoading,
                              expression: "treeLoading",
                            },
                          ],
                          key: _vm.keyTree,
                          ref: "buttonTree",
                          attrs: {
                            data: _vm.treeData,
                            defaultProps: _vm.treeProps,
                            btnOps: _vm.btnOps,
                            filterText: _vm.filterText,
                            filterNodeMethod: _vm.filterNodeMethod,
                            defaultExpandedKeys: _vm.defaultExpandedKeys,
                            currentNodeKey: _vm.currentNodeKey,
                            highlightCurrent: true,
                            "expand-on-click-node": false,
                          },
                          on: {
                            buttonClick: _vm.buttonNode,
                            nodeClick: _vm.nodeClick,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "treeSwith", on: { click: _vm.treeSwith } },
                  [
                    _vm.treeShow
                      ? _c("img", { attrs: { src: _vm.treeOffImgSrc } })
                      : _vm._e(),
                    !_vm.treeShow
                      ? _c("img", { attrs: { src: _vm.treeOnImgSrc } })
                      : _vm._e(),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "姓名", placeholder: "请输入姓名" },
                  model: {
                    value: _vm.searchParams.userName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "userName", $$v)
                    },
                    expression: "searchParams.userName",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "手机号", placeholder: "请输入手机号" },
                  model: {
                    value: _vm.searchParams.mobileNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "mobileNum", $$v)
                    },
                    expression: "searchParams.mobileNum",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "工号", placeholder: "请输入工号" },
                  model: {
                    value: _vm.searchParams.jobNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "jobNum", $$v)
                    },
                    expression: "searchParams.jobNum",
                  },
                }),
                _c("v-select", {
                  attrs: {
                    label: "性别",
                    placeholder: "请选择性别",
                    options: _vm.sexOps,
                  },
                  model: {
                    value: _vm.searchParams.sex,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "sex", $$v)
                    },
                    expression: "searchParams.sex",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "账号类型", options: _vm.roleTypeFilterOps },
                  model: {
                    value: _vm.searchParams.roleType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "roleType", $$v)
                    },
                    expression: "searchParams.roleType",
                  },
                }),
                _vm.searchParams.roleType != "107" && _vm.searchParams.roleType
                  ? [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              label: "管理范围",
                              subjoin: _vm.roleExtraParams,
                            },
                            model: {
                              value: _vm.searchParams.organizeId,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchParams, "organizeId", $$v)
                              },
                              expression: "searchParams.organizeId",
                            },
                          },
                          "v-select2",
                          _vm.organizeParams,
                          false
                        )
                      ),
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              label: "角色",
                              subjoin: _vm.rolesExtraParams,
                            },
                            model: {
                              value: _vm.searchParams.roleId,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchParams, "roleId", $$v)
                              },
                              expression: "searchParams.roleId",
                            },
                          },
                          "v-select2",
                          _vm.roleParams,
                          false
                        )
                      ),
                    ]
                  : _vm._e(),
                _c("v-select", {
                  attrs: { label: "账号状态", options: _vm.statusOps },
                  model: {
                    value: _vm.searchParams.accountStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "accountStatus", $$v)
                    },
                    expression: "searchParams.accountStatus",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { permission: "edit", text: "基本信息", type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.edit(scope.row)
                    },
                  },
                }),
                scope.row.accountStatus != 2
                  ? _c(
                      "el-dropdown",
                      { attrs: { trigger: "click" } },
                      [
                        _c("span", { staticClass: "el-dropdown-link" }, [
                          _vm._v("\n          更多"),
                          _c("i", {
                            staticClass: "el-icon-arrow-down el-icon--right",
                          }),
                        ]),
                        scope.row.accountStatus != 2
                          ? _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  [
                                    _vm.treeType == 2
                                      ? _c("v-button", {
                                          attrs: {
                                            text: "职责",
                                            permission: "responsibility",
                                            type: "text",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.responsibility(
                                                scope.row
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-dropdown-item",
                                  [
                                    _c("v-button", {
                                      attrs: {
                                        text: "关闭",
                                        permission: "delete",
                                        type: "text",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.close(scope.row)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "batchSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "批量关闭", permission: "batchDelete" },
                  on: {
                    click: function ($event) {
                      return _vm.batchRemove(scope.selectedData)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: { text: "批量移动", permission: "batchMove" },
                  on: {
                    click: function ($event) {
                      return _vm.batchMove(scope.selectedData)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-tree__node",
          attrs: {
            title: _vm.dialogText,
            width: "540px",
            visible: _vm.dialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              key: _vm.key,
              ref: "form",
              attrs: { "label-width": "100px", model: _vm.form },
            },
            [
              _vm.dialogValue === 1 || _vm.dialogValue === 3
                ? [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "组织名称",
                          prop: "orgName",
                          rules: [
                            {
                              required: true,
                              message: "请输入组织名称",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-input", {
                          attrs: {
                            placeholder: "请输入组织名称",
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.orgName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "orgName", $$v)
                            },
                            expression: "form.orgName",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.dialogValue === 2
                ? [
                    _c("p", { staticClass: "p-mar-0" }, [
                      _vm._v("确定删除组织节点："),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.currentData &&
                              _vm.currentData[_vm.treeProps.label]
                          )
                        ),
                      ]),
                    ]),
                  ]
                : _vm._e(),
              _vm.dialogValue === 4
                ? [
                    _c("p", { staticClass: "p-text p-pad-l" }, [
                      _vm._v("组织节点 "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.currentData &&
                              _vm.currentData[_vm.treeProps.label]
                          )
                        ),
                      ]),
                    ]),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "移动至",
                          prop: "parentId",
                          rules: [
                            {
                              required: true,
                              message: "请选择组织节点",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("select-tree", {
                          attrs: {
                            value: _vm.form.parentId,
                            placeholder: "查询组织节点",
                            treeData: _vm.treeData,
                            treeProps: _vm.treeProps,
                            highlightCurrent: true,
                            filterNodeMethod: _vm.filterNodeMethod,
                            "expand-on-click-node": false,
                            width: _vm.width,
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(_vm.form, "parentId", $event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.dialogValue === 6
                ? [
                    _c("p", { staticClass: "p-text p-pad-l" }, [
                      _vm._v("所有人员共" + _vm._s(_vm.ids.length) + "人"),
                    ]),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "移动至",
                          prop: "parentId",
                          rules: [
                            {
                              required: true,
                              message: "请选择组织节点",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("select-tree", {
                          attrs: {
                            value: _vm.form.parentId,
                            data: _vm.treeOrgData,
                            placeholder: "查询组织节点",
                            treeData: _vm.treeData,
                            treeProps: _vm.treeProps,
                            highlightCurrent: true,
                            filterNodeMethod: _vm.filterNodeMethod,
                            "expand-on-click-node": false,
                            width: _vm.width,
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(_vm.form, "parentId", $event)
                            },
                            "update:data": function ($event) {
                              _vm.treeOrgData = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.dialogValue === 5
                ? [
                    _c("p", { staticClass: "p-mar-0" }, [
                      _vm._v("确定删除公司 "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(_vm._s(_vm.currentData[_vm.treeProps.label])),
                      ]),
                      _vm._v("？"),
                    ]),
                    _c("p", { staticClass: "p-mar-0" }, [
                      _vm._v("删除公司后，该公司下所有成员账号立即失效！"),
                    ]),
                  ]
                : _vm._e(),
              _vm.dialogValue === 7
                ? [
                    _c("p", { staticClass: "p-mar-0" }, [
                      _vm._v("组织节点 "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(_vm._s(_vm.currentData[_vm.treeProps.label])),
                      ]),
                      _vm._v(
                        " 下存在成员，如需删除该组织节点，请移除组织下成员"
                      ),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.dialogValue !== 7
                ? _c("v-button", {
                    staticStyle: { "margin-right": "10px" },
                    attrs: { text: "取 消" },
                    on: {
                      click: function ($event) {
                        _vm.dialogVisible = false
                      },
                    },
                  })
                : _vm._e(),
              _c("v-button", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                attrs: { type: "primary", text: "确 定" },
                on: { click: _vm.clickCheck },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }